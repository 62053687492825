import { Component, signal, ViewChild } from '@angular/core';
import { OrganizationsService } from '@features/organizations/services/organizations.service';
import { PageOrganizationsComponent } from '../page-organizations/page-organizations.component';

@Component({
  selector: 'app-home-organization',
  templateUrl: './home-organization.component.html',
  styleUrl: './home-organization.component.scss',
})
export class HomeOrganizationComponent {
  organizationId = signal<number | null>(null);
  @ViewChild(PageOrganizationsComponent) pageOrganizationsComponent!: PageOrganizationsComponent;

  constructor(private readonly organizationsService: OrganizationsService) {
    this.organizationId.set(this.organizationsService.getOrganizationCookie());
  }

  onChangeOrganization(id: any) {
    this.organizationId.set(id);
    this.organizationsService.saveOrgnaizationCookie(id);
    this.pageOrganizationsComponent.onChangeOrganization(id);
  }
}

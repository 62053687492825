import { Component, input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-table-organizations',
  templateUrl: './status-table-organizations.component.html',
  styleUrl: './status-table-organizations.component.scss',
})
export class StatusTableOrganizationsComponent {
  data = input.required<any>();

  constructor(public router: Router) {}

  get statusColumnName() {
    return this.data().title === 'Connectors' ? 'Status' : 'Connect Status';
  }
}

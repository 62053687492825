import { Component, input } from '@angular/core';
import { ProductItemDTO } from '../../models';

@Component({
  selector: 'app-product-tile-organizations',
  templateUrl: './product-tile-organizations.component.html',
  styleUrl: './product-tile-organizations.component.scss',
})
export class ProductTileOrganizationsComponent {
  product = input.required<ProductItemDTO, ProductItemDTO>({
    transform: (item: ProductItemDTO) => {
      item['imgUrlLight'] = `assets/products/Light/${this.productsMap.get(item.link_type)}`;
      item['imgUrlDark'] = `assets/products/Dark/${this.productsMap.get(item.link_type)}`;
      return item;
    },
  });

  productsMap = new Map([
    ['DataHub', 'DataHub.png'],
    ['AirFlow', 'ApacheAirflow.png'],
    ['AirByte', 'Airbyte.png'],
    ['QVERA', 'Qvera.png'],
    ['SFTP', 'SFTP.png'],
    ['Snowflake', 'Snowflake.png'],
    ['ThoughtSpot', 'ThoughSpot.png'],
  ]);
}

@if (data(); as data) {
  <mat-card>
    <mat-card-header>
      <h2>Status of {{ data.title }}</h2>
    </mat-card-header>
    <mat-card-content>
      <div class="table-content">
        <table mat-table matSort [dataSource]="data.dataSource" class="mat-elevation-z8">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- Approval Status Column -->
          <ng-container matColumnDef="approval_status">
            <th mat-header-cell *matHeaderCellDef>Approval Status</th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-set
                aria-label="approval status"
                class="status-chip"
                [ngClass]="element.approval_status | statusColor"
              >
                <mat-chip>{{ element.approval_status }}</mat-chip>
              </mat-chip-set>
            </td>
          </ng-container>

          <!-- Connect Status Column -->
          <ng-container matColumnDef="connect_status">
            <th mat-header-cell *matHeaderCellDef>{{ statusColumnName }}</th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-set
                aria-label="connect status"
                class="status-chip"
                [ngClass]="
                  element.connect_status === 'Approved'
                    ? (element.connect_status + 'connector' | statusColor)
                    : (element.connect_status | statusColor)
                "
              >
                <mat-chip>{{ element.connect_status }}</mat-chip>
              </mat-chip-set>
            </td>
          </ng-container>

          <!-- Last Run Column -->
          <ng-container matColumnDef="last_connector_run_end">
            <th mat-header-cell *matHeaderCellDef>Last Run Date/time</th>
            <td mat-cell *matCellDef="let element">{{ element?.last_connector_run_end | dateFormat }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="data.columns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: data.columns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
              @if (data.dataSource.length === 0) {
                <div class="empty-table">
                  <div class="empty-table-message-info">No information here yet</div>
                </div>
              } @else {
                <div class="mat-mdc-row mdc-data-table__cell">No data</div>
              }
            </td>
          </tr>
        </table>
      </div>
      <button mat-button color="primary" type="button" class="action-button" (click)="router.navigateByUrl(data.url)">
        see more
      </button>
    </mat-card-content>
  </mat-card>
}

@if (product(); as product) {
  <a [href]="product.url" target="_blank">
    <mat-card class="col productTile">
      <mat-card-content class="justify-content-center">
        <img [src]="product.imgUrlLight" [alt]="product.link_type" class="display-light" loading="lazy" />
        <img [src]="product.imgUrlDark" [alt]="product.link_type" class="display-dark" loading="lazy" />
      </mat-card-content>
    </mat-card>
  </a>
}

<mat-toolbar>
  <div class="container">
    <a class="navbar-brand" href="/">
      <img src="assets/main_logo.png" alt="Logo" loading="lazy" class="brand-logo display-light" />
      <img src="assets/main_logo_dark.png" alt="Logo" loading="lazy" class="brand-logo display-dark" />
    </a>
  </div>
  <div class="col"></div>
</mat-toolbar>
<div class="container">
  <error-page-content>
    <h1 class="error-code">404</h1>
    <h2 class="error-main-text">Sorry, we couldn't find that page.</h2>
    <p>
      The page you are looking for doesn't exist or have been removed.
      <br />
      Please try our Home Page for the Portal or our Corporate Site:
    </p>
    <div class="row">
      <div class="col-6">
        <a mat-stroked-button color="primary" class="error-nav-button" [href]="main_site_url">Corporate Site</a>
      </div>
      <div class="col-6">
        <a mat-flat-button color="primary" class="error-nav-button" href="/">Home Page</a>
      </div>
    </div>
  </error-page-content>
</div>
